<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader class="text-center bg-dark text-white">
          <b>{{$t('label.listTypeVessel')}}</b>
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol sm="12" class="d-flex justify-content-end">
              <CButton
                shape="square"
                color="add"
                class="d-flex align-items-center"
                @click="toggleAdd"
                v-c-tooltip="{
                  content: $t('label.vesselType'),
                  placement: 'top-end'
                }"
              >
                <CIcon name="cil-playlist-add"/><span class="ml-1">{{$t('label.nuevo')}}</span>
              </CButton>
            </CCol>
            <CCol sm="12">
              <dataTableExtended
     class="align-center-row-datatable"
                hover
                sorter
                column-filter
                :table-filter="tableText.tableFilterText"
                :items-per-page-select="tableText.itemsPerPageText"
                :items="formatedItems"
                :fields="fields"
                :loading="isLoading"
                :noItemsView="tableText.noItemsViewText"
                :items-per-page="5"
                :active-page="activePage"
                pagination
                 
              >
                <template #loading>
                  <loading/>
                </template>
                <template #RowNumber="{ item }">
                  <td class="center-cell">
                    {{ item.RowNumber }}
                  </td>
                </template>

                <template #TpVesselName="{ item }">
                  <td class="center-cell text-left">
                    {{ item.TpVesselName }}
                  </td>
                </template>
                <template #Descripcion="{ item }">
                  <td class="center-cell text-left">
                    {{ item.Descripcion }}
                  </td>
                </template>

                <template #Foto="{ item }">
                  <td class="center-cell text-center">
                    <div class="row justify-content-center">
                      <c-img-extended 
                        :src="item.Foto"
                        :key="item.RowNumber"
                        :error-options="{ width: 500, height: 250, fontSize: 65, dy: 25 }"
                        block
                        thumbnail
                        class="img-registro"
                      />
                    </div>
                  </td>
                </template>

                <template #Ultimo="{ item }">
                  <td class="center-cell text-left">
                    {{ item.Ultimo }}
                  </td>
                </template>
                <template #FormatedDate="{ item }">
                  <td class="center-cell text-center">
                    {{ item.FormatedDate }}
                  </td>
                </template>

                <template #Status="{item}">
                  <td class="center-cell text-center">
                    <div>
                      <CBadge :color="getBadge(item.Status)">
                        {{ $t('label.'+item.Status) }}
                      </CBadge>
                    </div>
                  </td>
                </template>

                <template #Details="{item, index}">
                  <td class="py-2 center-cell">
                    <CButton
                      color="edit"
                      size="sm"
                      v-c-tooltip="{
                        content: $t('label.edit')+$t('label.vesselType'),
                        placement: 'top-end'
                      }"
                      @click="toggleEdit(item, index)"
                    >
                      <CIcon name="pencil" />
                    </CButton>
                  </td>
                </template>
              </dataTableExtended>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
    
    <add-tp-buque :modal.sync="modalAdd" @submited="handleSubmit"/>
    <edit-tp-buque :modal.sync="modalEdit" :tipo-buque-data="tpBuque" @submited="handleSubmit"/>
  </CRow>
</template>

<script>
import { DateFormater } from '@/_helpers/funciones';
import CImgExtended from '@/components/CImgExtended';
import GeneralMixin from '@/_mixins/general';

import AddTpBuque  from './add-tipo-buque-modal';
import EditTpBuque from './edit-tipo-buque-modal';



//Funcion data() del ciclo.
function fields() {
  return [
    { 
      key: 'RowNumber', 
      label: '#',
      filter: false,
      _style: 'width:1%; text-align:center;',
    },
    {
      key: 'TpVesselName',
      label:this.$t('label.vesselType'),
      _style: 'width:25%;', _classes:'text-left'
    },
    {
      key: 'Descripcion',
      label:this.$t('label.vesselTypeDescription'),
      _style: 'width:25%;', _classes:'text-left'

    },
    { 
      key: 'Foto',
      label: this.$t('label.picture'),
      sorter: false,
      filter: false,
      _style: 'width: 12%; text-align:center;',
      _classes:'text-center'
    },
    { key: 'Ultimo', label: this.$t('label.user'), _style: 'width: 10%;', _classes:'text-left'},
    { key: 'FormatedDate', label: this.$t('label.date'), _style: 'width: 10%;', _classes:'text-center'},
    { key: 'Status', label: this.$t('label.status'), _style:'width:10%;', _classes:'text-center'},
    { 
      key: 'Details', 
      label: '', 
      _style: 'width:50px', 
      sorter: false, 
      filter: false,
      _classes:'text-center'
    }
  ];
}

function data() {
  return {
    items: [],
    tpBuque: {},
    activePage: 1,
    isLoading: false,
    modalAdd: false,
    modalEdit: false,
  };
}

//*****Funcion created() del ciclo.
function created() {
  this.getTpBuques();
}

//*****Funciones de methods:
function getTpBuques() {
  this.isLoading = true;

  this.$http.get('TpVessel-list', { Filter: 'ALL' })
  .then(response => {
    this.items = response.data.data;
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isLoading = false;
  });
}

function toggleAdd() {
  this.modalAdd = true;
}
function toggleEdit(item, index) {
  this.tpBuque = Object.assign({}, item);

  this.modalEdit = true;
}
function handleSubmit(){
  this.getTpBuques();
}

//*****Funcones de computed:
function formatedItems() {

  return this.items.map((item) => Object.assign({}, item, {
    RowNumber: item.Nro,
    Descripcion: item.TpVesselDs.length > 50? `${item.TpVesselDs.substring(0, 50)}...`:item.TpVesselDs,
    Foto: `${this.$store.getters["connection/getBase"]}${item.Route.replace('public/', '', null,'i')}`,
    Ultimo: item.TransaLogin?item.TransaLogin:'N/A',
    FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : '22/12/2020',
    _classes: item.Status != 'ACTIVO'? 'table-danger': ''
  }));
}

export default {
  name: 'tipos-buque',
  mixins: [GeneralMixin],
  components: { AddTpBuque, EditTpBuque, CImgExtended },
  data,
  created,
  methods: {
    getTpBuques,
    toggleAdd,
    toggleEdit,
    handleSubmit
  },
  computed: {
    formatedItems,
    fields,
  }
}
</script>

<style lang="scss"scoped>
.img-registro{
  width: 8em;
  height: 4em;
}
/*
.center-cell {
  vertical-align: middle;
}*/
.center-cell {
  text-align: center;
}
 
</style>